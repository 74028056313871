import React from 'react'; 
import { Route, Routes } from 'react-router';
import Login from './components/login/login';
import Home from './components/Home/home';

const App = () => {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/login' element={<Login/>} />
      </Routes>
    </>
  );
};

export default App;
