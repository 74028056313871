// ProfileSection.js
import React from 'react';
import { Link } from 'react-router-dom';

const ProfileSection = ({ imageUrl, name}) => {
  return (
<Link  className='flex items-center w-full md:w-64 h-12 md:h-[48px] gap-3 px-2 py-4 bg-white'>
  <div className='h-8 md:h-[32px] gap-3 flex items-center justify-between'>
    <img
      src={imageUrl}
      alt="Profile"
      className="w-8 h-8 md:w-12 md:h-12 rounded-full"
    />
    <span className='hidden md:block h-5 font-medium text-sm text-[#18191F]'>{name}</span>
  </div>
  </Link>


  );
}

export default ProfileSection;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const MicrosoftProfile = () => {
//   const [profile, setProfile] = useState(null);

//   useEffect(() => {
//     const fetchProfile = async () => {
//       try {
//         // Make a request to Microsoft Graph API to fetch user details.
//         const response = await axios.get('https://graph.microsoft.com/v1.0/me', {
//           headers: {
//             Authorization: `Bearer ${YOUR_ACCESS_TOKEN_HERE}`, // Replace with your access token
//           },
//         });

//         // Set the profile state with the fetched data
//         setProfile(response.data);
//       } catch (error) {
//         console.error('Error fetching profile:', error);
//       }
//     };

//     fetchProfile();
//   }, []);

//   if (!profile) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <h1>Welcome, {profile.displayName}</h1> {/* Displaying the name */}
//       <img src={profile.photo ? profile.photo : 'default-image-url-here'} alt="Profile" /> {/* Displaying the image, use a default image if none available */}
//     </div>
//   );
// };

// export default MicrosoftProfile;
