import React, { useState } from 'react';
import MultipleFileUpload from '../fileupload/MultipleFileUpload';

const NoData = ({ isSidebarOpen }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 z-10 flex items-center justify-center backdrop-blur p-2 overflow-hidden">
          <div className="relative">
            <button onClick={closeModal} className="absolute top-2 right-2 text-red-600 hover:text-[#8e3636b3] focus:outline-none">
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <MultipleFileUpload onClose={closeModal} />
          </div>
        </div>
      )}

      <div className={`w-${isSidebarOpen ? '[1184px] fill' : 'full'} h-auto flex-grow flex p-4 justify-between items-center`}>
        <div className='flex flex-grow justify-between gap-[12px]'>
          <div className='flex items-center justify-between gap-[12px] flex-grow'>
            <span className='font-Inter font-semibold text-[20px] text-[#18191F]'>File Repository</span>
          </div>
        </div>
      </div>

      <div className={`flex-grow w-${isSidebarOpen ? '[1184px]' : 'full'} h-full flex items-center px-4 pb-4  justify-center`}>
        <div className='w-full flex-grow h-full gap-2 rounded-[8px] border-[4px] border-dashed border-[#E5E7EB] bg-white flex flex-col items-center justify-center'>
          <span className='w-[200px] h-[28px] font-Inter font-bold text-[19px] whitespace-nowrap text-[#18191F]'>No file uploaded yet!</span>
          <button onClick={() => setIsModalOpen(true)} className='w-[81px] h-[34px] rounded-[6px] bg-[#36678E] shadow-md'>
            <span className='w-[55px] h-[16px] font-Inter font-medium text-[14px] text-white'>Add File</span>
          </button>
        </div>
      </div>
    </>

  );
};

export default NoData;
