import React from 'react';
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { LuUsers2 } from "react-icons/lu";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { IoLogOutOutline } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";

const Sidebar = () => {
  const logout = () => {
    localStorage.setItem("isAuthenticated", "");
    localStorage.setItem("token", "");
    
    window.location.href = process.env.REACT_APP_REDIRECT_URL+"Identity/Account/Login";
  };

  return (
    <div className='w-full md:w-[256px] h-full bg-[#36678E]'>
      <div className='w-full md:w-[256px] h-[146px] p-[16px] md:gap-[24px] shadow-md flex items-center justify-center'>
        <div className='flex items-center'>
          <span className='w-[125px] h-[22px] font-Inter font-normal text-[18px] whitespace-nowrap text-[#FFFFFF]'>File Uploader</span>
        </div>
        <FiUpload className='w-[24px] h-[24px] text-[#FFFFFF] ml-[8px]' />
      </div>

      <div className='w-full md:w-[256px] px-[8px] md:gap-[16px]'>
        <div className='w-full md:w-[240px] md:gap-[4px] relative'>
          <div className='w-full md:w-[240px] h-[40px] pt-[9px] pr-[12px] pb-[9px] pl-[8px] rounded-[6px] gap-[12px] bg-[#36678E]'>
            <Link to='/'>
              <div className='w-full md:w-[220px] h-[22px] gap-[12px] flex items-center'>
                <MdOutlineSpaceDashboard className='w-[19.83px] h-[15.69px] top-[2.23px] left-[0.16px] text-white' />
                <span className='w-[31px] h-[22px] font-medium text-[14px] text-white'>Files</span>
              </div>
            </Link>
          </div>

          <div className='w-full md:w-[240px] h-[40px] pt-[9px] pr-[12px] pb-[9px] pl-[8px] rounded-[6px] gap-[12px] bg-[#36678E]'>
            <div className='w-full md:w-[220px] h-[22px] gap-[12px] flex items-center'>
              <LuUsers2 className='w-[19.83px] h-[15.69px] top-[2.23px] left-[0.16px] text-white' />
              <span className='w-[31px] h-[22px] font-medium text-[14px] text-white'>Users</span>
            </div>
          </div>

          <div className='w-full md:w-[240px] h-[40px] pt-[9px] pr-[12px] pb-[9px] pl-[8px] rounded-[6px] gap-[12px] bg-[#36678E]'>
            <div className='w-full md:w-[220px] h-[22px] gap-[12px] flex items-center'>
              <IoSettingsOutline className='w-[16.7px] h-[15.83px] top-[2.08px] left-[1.67px] text-white' />
              <span className='w-[31px] h-[22px] font-medium text-[14px] text-white'>Settings</span>
            </div>
          </div>
        </div>

        {/* Logout */}
        <div className='w-full md:w-[240px] h-[38px] rounded-[6px] pt-[8px] pr-[12px] pb-[8px] pl-[8px] absolute bottom-14'>
        <button onClick={logout}>  {/* Call logout function on click */}
          <div className='w-full md:w-[220px] h-[22px] gap-[12px] flex items-center justify-between'>
            <div className='flex items-center'>
              <IoLogOutOutline className='w-[15px] h-[15px] text-white' />
              <span className='ml-[4px] font-Inter font-medium text-[14px] text-[#ffffff]'>Logout</span>
            </div>
          </div>
        </button>
      </div>
      </div>
    </div>
  );
};


export default Sidebar;
