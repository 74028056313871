import React, { useState, useEffect, useCallback } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import axios from "axios";
import { IoIosArrowUp } from "react-icons/io";
import baseApiService from "../../services/baseApiService";

export function Pagination({ active, setActive, totalPages, itemsPerPage, handleItemsPerPageChange }) {
  const [currentPage, setCurrentPage] = useState(active);
  const [files, setFiles] = useState([]);

  const fetchFiles = useCallback(async (page) => {
    try {

      const response = await baseApiService.get(`${process.env.REACT_APP_API_BASE_URL}/pagination/files`, {
        params: {
          pageNumber: page,
          pageSize: itemsPerPage
        },
      });
      console.log("response", response);
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  }, [itemsPerPage]);

  useEffect(() => {
    fetchFiles(currentPage);
  }, [currentPage, fetchFiles]);

  const prev = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      setActive(newPage);
      fetchFiles(newPage);
    }
  };

  const next = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      setActive(newPage);
      fetchFiles(newPage);
    }
  };

  return (
    <div className="flex justify-between w-full">
      <div className="flex-grow flex items-center justify-between gap-4">
        <div className="relative w-[106px] font-inter font-normal text-sm">
          <select
            onChange={handleItemsPerPageChange}
            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer font-Inter"
            value={itemsPerPage}
          >
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
          <div onClick={() => { }} className="flex justify-between items-center w-full h-full px-3 py-2 border rounded-[4px] border-gray-300 bg-gradient-to-b from-white to-white text-[#D1D5DB] shadow-sm">
            <span className="text-[#6B7280] text-[12px] flex whitespace-nowrap items-center justify-start font-normal font-Inter">{itemsPerPage} / page</span>
            <IoIosArrowUp className="w-[15px] h-[15px]" />
          </div>
        </div>
        <div className="flex items-center justify-end w-full">
          <div className="w-[126px] h-[21px] flex items-center justify-center font-Inter text-[13px] font-normal px-3 py-2 whitespace-nowrap text-[#6B7280] mr-4">
            Showing {(currentPage - 1) * itemsPerPage + 1} - {currentPage * itemsPerPage} of {totalPages * itemsPerPage}
          </div>
          <div className="flex gap-[12px] w-[72px] h-[30px]">
            <button
              className={`p-[5px] rounded-[6px] gap-[8px] border-[1px] border-[#D1D5DB] bg-white ${currentPage === 1 ? 'text-gray-300' : 'text-[#36678E]'}`}
              onClick={prev}
              disabled={currentPage === 1}
            >
              <GrFormPrevious strokeWidth={2} className="h-4 w-4" />
            </button>
            <button
              className={`p-[5px] rounded-[6px] gap-[8px] border-[1px] border-[#D1D5DB] bg-white ${currentPage === totalPages ? 'text-gray-300' : 'text-[#36678E]'}`}
              onClick={next}
              disabled={currentPage === totalPages}
            >
              <GrFormNext strokeWidth={2} className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
