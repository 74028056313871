// axiosInstance.js

import axios from 'axios';

const baseApiService = axios.create();

baseApiService.interceptors.request.use(
  (config) => {
    // Retrieve the token from local storage or wherever it's stored
    const token = localStorage.getItem('token');
    
    // If token exists, add it to the request headers
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default baseApiService;
