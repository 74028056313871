import React from 'react';
import { PiTextOutdentBold } from "react-icons/pi";
import ProfileSection from '../profile/ProfileSection';

const Header = ({ isSidebarOpen }) => {
  // Mock user data (replace with actual user data from the backend)
  const userProfile = {
    imageUrl: '/Avatar.svg', 
    name: 'Jane Cooper' 
  };

  return (
    
<div className={`w-${isSidebarOpen ? '[1440px]' : 'full'} h-[72px]  p-4 flex justify-between items-center bg-white shadow-md`}>
      <div className='w-5 h-5 flex items-center cursor-pointer' >
        <PiTextOutdentBold className='w-[20px] h-[20px] text-[#042645]' />
      </div>
      <div className=''>
        <ProfileSection imageUrl={""} name={userProfile.name} />
      </div>
    </div>

  );
}

export default Header;
