import React from 'react'

const login = () => {
  return (
<div className='w-full h-full fixed top-[18px] left-[-1476] bg-[#F9FAFB] flex justify-center items-center '>
    <div className='w-[448px] h-[487px] flex flex-col items-center justify-center gap-[32px] '>
        <div className='w-[426px] h-[36px] flex justify-center items-center px-[20px] '>
            <span className='font-inter text-[28px] font-bold text-center text-[#111827] whitespace-nowrap'>
                Welcome, login to upload files!
            </span>
        </div>
        
        <div className='w-[448px] h-[419px] rounded-[8px] px-[32px] py-[40px] shadow-md bg-[#ffffff] '>
    <div className='w-[368px] h-[66px] gap-[4px]'>
        <label className='font-inter font-medium w-24 h-5 text-[#374151]'>Email address</label>
        <input className='w-[368px] h-[42px] px-[13px] py-[9px] rounded-[6px] border border-[#D1D5DB] shadow-sm' type='email' placeholder='Email'/>
    </div>
    <div className='mt-6 w-[368px] h-[66px] gap-[4px]'>
        <label className='font-inter  font-medium w-24 h-5 text-[#374151]'>Password</label>
        <input className='w-[368px] h-[42px] px-[13px] py-[9px] rounded-[6px] border border-[#D1D5DB] shadow-sm' type='password' placeholder='Password'/>
    </div>
    <button className='w-[368px] h-[42px] rounded-[6px] p-[9px 17px 9px 17px] bg-[#36678E] shadow-sm mt-6'>
        <span className='w-[49px] h-[24px] font-Inter font-medium text-[15px] text-white'>Sign in</span>
    </button>
    <div className='w-[368px] h-[20px] flex items-center mt-6'>
    <span className='flex-grow h-[1px] bg-gray-300'></span>
    <span className='mx-4 w-[88px] h-[20px] font-inter text-[14px] font-normal text-center text-gray-500'>Or log in with</span>
    <span className='flex-grow h-[1px] bg-gray-300'></span>
</div>

<div className='w-[368px] h-[65px] mt-6 flex  justify-center'>
    <span className='font-inter text-[15px] font-medium text-center text-black'>Microsoft Azure SSO</span>
</div>


</div>

        
    </div>
</div>





  )
}

export default login